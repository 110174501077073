import { gql } from 'apollo-boost';

// startDate: "2020-11-01T01:00:00.857Z",
// endDate: "2021-12-30T23:01:16.857Z",

export const GET_SALES_DATA = gql`
  query SalesDataQuery($startDate : String!, $endDate : String!){
  salesData(input: {
    startDate : $startDate,
    endDate : $endDate,
    status: [ "exception", "expired", "completed", "reserved"]

  }) {
    products {
      status
      reservedAt
      ejectedAt
      pickupCode
      receipt {
        pickupCode
        url
      }
      transactionId
      reservedBy {
        name
        role
        _id
      }
      product {
        productId
        vendorId
        vendorName
        name
        vmId
        machineId
        amount
        nominalPrice
        salesPrice
        totalPrice
        currency
        discountType
        discountInfo
        status
        tcposId
        articleCode
      }
    }
  }
}

`;

export const GET_RECEIPT = gql`
  query ReceiptQuery($pickupCode: String!) { 
    receipt(input: { pickupCode: $pickupCode })
  }
`;

export const GET_RECEIPTS = gql`
  query ReceiptQuery($startDate: Date!, $endDate: Date!) { 
    receipts(input: { startDate: $startDate, endDate: $endDate })
  }
`;