import styled from 'styled-components';

export const AppWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1500px;
  padding-bottom: 35px;
`;

export const PageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  width: 100%;
`;

export const LightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.white};
`;

export const ContentBox = styled.div`
  max-width: 1312px;
  box-sizing: border-box;
  width: calc(100% - 60px);
  flex-grow: ${({ grow }) => (grow ? '1' : 'unset')};
`;

export const ControlsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  margin-top: 48px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.lightGrayBlue}`};
`;

export const HeaderContainer = styled.div`
  margin: 48px 0;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const BackLinkHeaderContainer = styled.div`
   height: 47px;
   margin: 5px 0 32px 0;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
`;

export const Container = styled.div`
  width: calc(100% - 60px);
  ${({ maxWidth }) => (
    maxWidth ? `max-width: ${maxWidth};` : ''
  )}
`;

export const StyledForm = styled.form`
  min-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: ${({ grow }) => (grow ? '1' : 'unset')};
`;

export const EditPageContent = styled(ContentBox)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ColumnInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 48px;
  width: 100%;
`;

export const RowInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  min-width: 1024px;
  max-width: 1344px;
  width: 100%;
`;

export const ContentContainer = styled(ContentBox)`
  display: flex;
  align-items: flex-start;
`;

export const HighLightedText = styled.span`
  color: ${({ theme, color }) => color || theme.palette.greenBlueLightened};
`;

export const StyledHeader = styled(BackLinkHeaderContainer)`
  margin-bottom: 24px;
`;
