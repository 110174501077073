import styled from 'styled-components';

export const Row = styled.div`
  margin-top: 10px;
  margin-bottom: 50px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: left;
`;

export const ColumnDate = styled.div`
  margin-left: 30px;
  width:220px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const ColumnStop = styled.div`
  margin-left: 30px;
  width:220px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const ColumnButton = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  width:220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  text-align: left;
`;

export const RowSelect = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  width: 1200px;
  display: flex;
  justify-content: flex-end;
  align-items: left;
`;