import React, { useState } from 'react';
import { InfoTitle } from 'components';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Button } from 'components';
import Papa from 'papaparse';
import { GET_RECEIPTS } from './queries';
import { useQuery } from '@apollo/react-hooks';

export const DownloadButtons = (props) => {
  return (
    <div>
      <InfoTitle>
        <DownloadPdfButton {...props} />
        <DownloadCsvButton {...props} style={{ marginLeft: '20px' }} />
      </InfoTitle>
    </div>
  );
};

export const DownloadPdfButton = ({ fileName, tableID, title }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    autoTable(doc, { html: tableID });
    doc.text(title, 10, 10);
    doc.save(`${fileName}.pdf`);
  };

  return (
    <Button onClick={generatePDF} type="primary">
      Download pdf
    </Button>
  );
};

export const DownloadCsvButton = ({ fileName, tableID, ...props }) => {
  const generateCSV = () => {
    // Select the table element
    const table = document.getElementById(tableID.substring(1));
    console.log(table);
    const rows = Array.from(table.querySelectorAll('tr'));

    // Extract data from each row
    const data = rows.map((row) => {
      const cells = Array.from(row.querySelectorAll('th, td'));
      return cells.map((cell) => {
        if (cell.hasAttribute('data-receipt-url')) {
          const receiptUrl = cell.getAttribute('data-receipt-url');
          return receiptUrl || '';
        }
        return cell.textContent;
      });
    });

    // Convert data to CSV format
    const csv = Papa.unparse(data);

    // Create a Blob from the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link to download the CSV file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button style={{ ...props.style }} onClick={generateCSV} type="primary">
      Download csv
    </Button>
  );
};

export const DownloadReceiptsButton = ({ startDate, endDate, ...props }) => {
  const { refetch } = useQuery(GET_RECEIPTS, {
    skip: true,
    variables: { startDate: startDate, endDate: endDate }
  });

  const [loading, setLoading] = useState(false);

  const downloadReceipts = async () => {
    try {
      setLoading(true);
      const response = await refetch();
      downloadBase64File(response.data.receipts, 'receipts.zip');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadBase64File = (base64Data, fileName) => {
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: 'application/zip' });
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button disabled={loading} style={{ ...props.style }} onClick={downloadReceipts} type="primary">
      Download receipts
    </Button>
  );
};
