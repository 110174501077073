import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateFnsUtils from '@date-io/date-fns';

import { Row, ColumnDate, ColumnButton } from './styled';

import { Button } from 'components';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { InfoTitle } from 'components';
import { DownloadCsvButton, DownloadReceiptsButton } from './DownloadButtons';
/*
allows to specify a date range from YY:MM:DD 00:00:00 to yy:mm:dd 23:59:59
The time component is kept invariant across updates. 
*/
const RangePicker = ({ isDetailsView, tableID, isVendor, ...props }) => {
  const { t } = useTranslation();

  const startDate = props.startDate;
  // force the hh:mm:ss t0 00:00:00
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  const [selStartDate, setSelStartDate] = useState(startDate);

  const endDate = props.endDate;
  // force the hh:mm:ss t0 23:59:59
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);

  const [selEndDate, setSelEndDate] = useState(endDate);

  const onSelStartDateChange = (date, value) => {
    // force the hh:mm:ss t0 00:00:00 -> only yy mm dd can be changed
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    setSelStartDate(date);
  };
  const onSelEndDateChange = (date, value) => {
    // force the hh:mm:ss t0 23:59:59 -> only yy mm dd can be changed
    // note that by default, if the date is typed in, the date picker sets the hours to 00:00:00
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    setSelEndDate(date);
  };

  const filter = () => {
    props.onStartDateChange(selStartDate);
    props.onEndDateChange(selEndDate);
  };

  return (
    <Row>
      <ColumnDate>
        <InfoTitle>{t('sales.start')}:</InfoTitle>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            className="datepicker"
            disableToolbar
            ampm={false}
            type="hours"
            minutesStep={60}
            views={['date']}
            variant="inline"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            id="start-datetime-picker-inline"
            value={selStartDate}
            onChange={onSelStartDateChange}
            maxDate={props.startOfToday}
          />
        </MuiPickersUtilsProvider>
      </ColumnDate>
      <ColumnDate>
        <InfoTitle>{t('sales.end')}:</InfoTitle>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            className="datepicker"
            disableToolbar
            ampm={false}
            type="hours"
            minutesStep={60}
            views={['date']}
            variant="inline"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            id="end-datetime-picker-inline"
            value={selEndDate}
            onChange={onSelEndDateChange}
            maxDate={props.endOfToday}
          />
        </MuiPickersUtilsProvider>
      </ColumnDate>
      <ColumnButton>
        <Button style={{ height: '56px' }} onClick={filter}>
          {t('sales.filter')}
        </Button>
      </ColumnButton>
      {isDetailsView && (
        <>
          <ColumnButton>
            <DownloadCsvButton style={{ height: '56px' }} fileName="salesDetails" tableID={tableID} />
          </ColumnButton>
          {isVendor && (
            <ColumnButton>
              <DownloadReceiptsButton startDate={selStartDate} endDate={selEndDate} style={{ height: '56px' }} />
            </ColumnButton>
          )}
        </>
      )}
    </Row>
  );
};

export default RangePicker;
