import React, { useState, useContext, createContext } from 'react';

const SalesContext = createContext();

export const SalesContextProvider = ({ children }) => {
  const startOfToday = new Date();
  startOfToday.setHours(0);
  startOfToday.setMinutes(0);
  startOfToday.setSeconds(0);

  const endOfToday = new Date();
  endOfToday.setHours(23);
  endOfToday.setMinutes(59);
  endOfToday.setSeconds(59);

  const [startDate, setStartDate] = useState(startOfToday);
  const [endDate, setEndDate] = useState(endOfToday);

  return <SalesContext.Provider value={{ startDate, setStartDate, endDate, setEndDate }}>{children}</SalesContext.Provider>;
};

export const useSalesContext = () => useContext(SalesContext);
