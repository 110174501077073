import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Error, ProgressWrapper } from 'components';
import { convertGqlError } from 'utils';
import { TableContainer } from 'components';
import { GET_SALES_DATA } from './queries';

import RangePicker from './RangePicker';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from 'components';
import { Link } from 'react-router-dom';
import { NESTED_ROUTES, ROUTES } from 'configs';
import { USER } from 'hooks/graphQL/queries';
import { useSalesContext } from 'context/SalesContext';

const SalesDetailsView = () => {
  const { t } = useTranslation();
  const {
    data: { role }
  } = useQuery(USER);
  const isVendor = role === 'vendor';
  const { startDate, setStartDate, endDate, setEndDate } = useSalesContext();

  const startOfToday = new Date();
  startOfToday.setHours(0);
  startOfToday.setMinutes(0);
  startOfToday.setSeconds(0);

  const endOfToday = new Date();
  endOfToday.setHours(23);
  endOfToday.setMinutes(59);
  endOfToday.setSeconds(59);

  return (
    <div>
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        startOfToday={startOfToday}
        endOfToday={endOfToday}
        isDetailsView={true}
        isVendor={isVendor}
        tableID={'#sales-details-table'}
      ></RangePicker>
      <SalesListMTable startDate={startDate} endDate={endDate} />
    </div>
  );
};

Date.prototype.yyyy_mm_dd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('/');
};

export const SalesListMTable = (props) => {
  const { t } = useTranslation();
  const startDateStr = props.startDate.toISOString();
  const endDateStr = props.endDate.toISOString();
  const startDateLabel = props.startDate.yyyy_mm_dd();
  const endDateLabel = props.endDate.yyyy_mm_dd();

  //console.log('sales table has startDate=', startDateStr, 'endDate=', endDateStr)
  const { loading, error, data } = useQuery(GET_SALES_DATA, { variables: { startDate: startDateStr, endDate: endDateStr } });

  if (error) return <Error code={convertGqlError(error).code} />;
  if (loading) return <ProgressWrapper />;

  const products = data.salesData.products;
  console.log('products', products);

  const options = { hour12: false };
  return (
    <div>
      {
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="sales table" id="sales-details-table">
            <TableHead>
              <TableRow>
                <TableCell align="right">{t('sales.details.receipt')}</TableCell>
                <TableCell align="right">{t('sales.details.product_status')}</TableCell>
                <TableCell align="right">{t('sales.details.reservedAt')}</TableCell>
                <TableCell align="right">{t('sales.details.ejectedAt')}</TableCell>
                <TableCell component="th" scope="row">
                  {t('sales.details.status')}
                </TableCell>
                <TableCell align="right">{t('sales.details.pickupCode')}</TableCell>
                <TableCell align="right">{t('sales.details.transactionId')}</TableCell>
                <TableCell align="right">{t('sales.details.reservedBy_role')}</TableCell>
                <TableCell align="right">{t('sales.details.product_vendorName')}</TableCell>
                <TableCell align="right">{t('sales.details.product_name')}</TableCell>
                <TableCell align="right">{t('sales.details.product_articleCode')}</TableCell>
                <TableCell align="right">{t('sales.details.product_tcposId')}</TableCell>
                <TableCell align="right">{t('sales.details.product_machineId')}</TableCell>
                <TableCell align="right">{t('sales.details.product_amount')}</TableCell>
                <TableCell align="right">{t('sales.details.product_nominalPrice')}</TableCell>
                <TableCell align="right">{t('sales.details.product_salesPrice')}</TableCell>
                <TableCell align="right">{t('sales.details.product_totalPrice')}</TableCell>
                <TableCell align="right">{t('sales.details.product_currency')}</TableCell>
                <TableCell align="right">{t('sales.details.product_discountType')}</TableCell>
                <TableCell align="right">{t('sales.details.product_discountInfo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow key={'titi'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="right" data-receipt-url={row.receipt.url ?? ''}>
                    <Button
                      style={{ minWidth: '150px' }}
                      component={Link}
                      to={`${ROUTES.SALES}/${NESTED_ROUTES.RECEIPT}/${row.pickupCode}`}
                      disabled={!row.receipt.pickupCode}
                    >
                      {t('sales.details.show_receipt')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">{row.product.status}</TableCell>
                  <TableCell align="right">{new Date(row.reservedAt).toLocaleString('en-GB', options)}</TableCell>
                  <TableCell align="right">{new Date(row.ejectedAt).toLocaleString('en-GB', options)}</TableCell>
                  <TableCell component="th" scope="row">
                    {' '}
                    {row.status}
                  </TableCell>
                  <TableCell align="right">{row.pickupCode}</TableCell>
                  <TableCell align="right">{row.transactionId}</TableCell>
                  <TableCell align="right">{row.reservedBy?.role}</TableCell>
                  <TableCell align="right">{row.product.vendorName}</TableCell>
                  <TableCell align="right">{row.product.name}</TableCell>
                  <TableCell align="right">{row.product.articleCode}</TableCell>
                  <TableCell align="right">{row.product.tcposId}</TableCell>
                  <TableCell align="right">{row.product.machineId}</TableCell>
                  <TableCell align="right">{row.product.amount}</TableCell>
                  <TableCell align="right">{parseFloat(row.product.nominalPrice).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(row.product.salesPrice).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(row.product.totalPrice).toFixed(2)}</TableCell>
                  <TableCell align="right">{row.product.currency}</TableCell>
                  <TableCell align="right">{row.product.discountType}</TableCell>
                  <TableCell align="right">{row.product.discountInfo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  );
};

export default SalesDetailsView;
