const ERRORS = {
  NOT_FOUND: 'NOT_FOUND',
  BAD_USER_INPUT: 'BAD_USER_INPUT',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  CANT_EDIT_REFILL_REUEST: 'CANT_EDIT_REFILL_REUEST',
  FORBIDDEN: 'FORBIDDEN',
  UNAUTHENTICATED: 'UNAUTHENTICATED'
};

export default ERRORS;
