import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const LOGOUT = gql`
  mutation LOGOUT {
    logout @client
  }
`;

const Unauthenticated = () => {
    const [logout] = useMutation(LOGOUT);

    useEffect(() => {
        logout();
    }, [])

    return <></>
}

export default Unauthenticated;